import PropTypes from 'prop-types';
import artistType from './artistType';
import linksType from './linksType';

export default PropTypes.shape({
  Title: PropTypes.string,
  Description: PropTypes.string,
  ReleaseDate: PropTypes.string,
  Type: PropTypes.string,
  artists: artistType,
  Links: linksType,
  // eslint-disable-next-line react/forbid-prop-types
  Cover: PropTypes.object,
});
