import PropTypes from 'prop-types';
import linksType from './linksType';

export default PropTypes.shape({
  Name: PropTypes.string,
  Description: PropTypes.string,
  Links: linksType,
  // eslint-disable-next-line react/forbid-prop-types
  CoverImage: PropTypes.object,
});
