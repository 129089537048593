import PropTypes from 'prop-types';
import React from 'react';
import { releaseType } from '../../types';
import arrayHasValues from '../../util/arrayHasValues';
import ReleaseDisplayLg from './releaseDisplayLg';
import ReleaseDisplaySm from './releaseDisplaySm';

function getTopComponent(fulldate, artists, release, noDate) {
  let artistNames;
  if (artists && arrayHasValues(release.artists)) {
    artistNames = release.artists.map((artist) => artist.Name).join(', ');
  }

  return (
    <div className="text-gray-300 font-light text-sm sm:text-base lg:text-lg">
      {artistNames && (
        <>
          <span>{artistNames}</span>
          {!noDate && <span className="mx-2 text-gray-500">•</span>}
        </>
      )}
      {!noDate && (
        <span>
          {fulldate
            ? new Date(release.FullReleaseDate).toLocaleDateString()
            : release.ReleaseDate}
        </span>
      )}
    </div>
  );
}

function getBottomComponent(title) {
  return (
    <h3 className="text-gray-200 text-base sm:text-lg lg:text-2xl">{title}</h3>
  );
}

const ReleasesDisplay = ({
  releases,
  fulldate = false,
  artists = false,
  noDate = false,
}) => (
  <>
    <div className="lg:hidden flex gap-x-4 overflow-x-auto py-3">
      {releases.map((release) => (
        <ReleaseDisplaySm
          key={release.id}
          release={release}
          topComponent={getTopComponent(fulldate, artists, release, noDate)}
          bottomComponent={getBottomComponent(release.Title)}
        />
      ))}
    </div>
    <div className="hidden lg:grid grid-cols-2 lg:grid-cols-3 gap-4">
      {releases.map((release) => (
        <ReleaseDisplayLg
          key={release.id}
          release={release}
          topComponent={getTopComponent(fulldate, artists, release, noDate)}
          bottomComponent={getBottomComponent(release.Title)}
        />
      ))}
    </div>
  </>
);

ReleasesDisplay.propTypes = {
  releases: PropTypes.arrayOf(releaseType).isRequired,
  fulldate: PropTypes.bool,
  artists: PropTypes.bool,
  noDate: PropTypes.bool,
};

ReleasesDisplay.defaultProps = {
  fulldate: false,
  artists: false,
  noDate: false,
};

export default ReleasesDisplay;
