import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import useResizeObserver from 'use-resize-observer';
import { releaseType } from '../../types';
import slugify from '../../util/slugify';

const ReleaseDisplayLg = ({ release, topComponent, bottomComponent }) => {
  const ref = useRef(null);
  const { width } = useResizeObserver({ ref });

  // ugly solution to make sure the image is quadratic
  // tw-aspect cause problems
  useEffect(() => {
    ref.current.style.height = `${width}px`;
  }, [ref, width]);

  return (
    <Link
      ref={ref}
      to={`/releases/${slugify(release.Title)}`}
      className="rounded-xl relative flex flex-col overflow-hidden group focus:outline-none focus:ring focus:ring-gray-300"
      style={{ minHeight: '280px' }}
    >
      <GatsbyImage
        image={release.Cover.localFile.childImageSharp.gatsbyImageData}
        className="!absolute inset-0 object-cover pointer-events-none select-none rounded w-full h-full"
        alt={`${release.Title} cover`}
        // needed for safari to show full height
        style={{ height: '-webkit-fill-available' }}
      />
      <span
        aria-hidden="true"
        className="absolute inset-x-0 bottom-0 h-full md:h-1/2 bg-gradient-to-t from-black opacity-80"
      />
      <span
        aria-hidden="true"
        className="absolute inset-x-0 bottom-0 h-full bg-gray-100 transition-opacity duration-400 ease-in-out opacity-0 group-hover:opacity-10"
      />
      <div className="relative mt-auto p-4" style={{ minWidth: '20vw' }}>
        {topComponent}
        {bottomComponent}
      </div>
    </Link>
  );
};

ReleaseDisplayLg.propTypes = {
  release: releaseType.isRequired,
  topComponent: PropTypes.elementType.isRequired,
  bottomComponent: PropTypes.elementType.isRequired,
};

export default ReleaseDisplayLg;
