import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { PageH1, PageH2 } from '../components/headers';
import Layout from '../components/layout';
import ReleasesDisplay from '../components/release-display/releasesDisplay';
import SEO from '../components/seo';
import { releaseType } from '../types';
import arrayHasValues from '../util/arrayHasValues';

export const query = graphql`
  {
    upcomingReleases: allStrapiReleases(
      filter: { isFuture: { eq: true } }
      sort: { fields: ReleaseDate, order: ASC }
      limit: 6
    ) {
      nodes {
        id
        Title
        ReleaseDate(formatString: "YYYY-MM-DD")
        Type
        artists {
          Name
        }
        Cover {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    lastReleases: allStrapiReleases(
      filter: { isFuture: { eq: false } }
      sort: { fields: ReleaseDate, order: DESC }
    ) {
      nodes {
        id
        Title
        ReleaseDate(formatString: "YYYY")
        Type
        artists {
          Name
        }
        Cover {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

const Releases = ({ data }) => {
  const { upcomingReleases, lastReleases } = data;

  const years = {};

  lastReleases.nodes.forEach((release) => {
    if (release.ReleaseDate in years) {
      years[release.ReleaseDate].push(release);
    } else {
      years[release.ReleaseDate] = [release];
    }
  });

  return (
    <Layout>
      <SEO title="Releases" />
      <div className="px-8 mb-24">
        <PageH1 classes="mt-8 mb-12">DQBT Releases</PageH1>
        {arrayHasValues(upcomingReleases.nodes) && (
          <div className="rounded-md border border-trueGray-700 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon
                  className="h-5 w-5 text-trueGray-700"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-trueGray-400">
                  {`${upcomingReleases.nodes.length} upcoming release(s) announced`}
                </p>
                <p className="mt-3 text-sm md:mt-0 md:ml-6">
                  <Link
                    to="/upcoming-releases"
                    className="whitespace-nowrap p-2 rounded font-medium text-indigo-300 hover:text-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-300"
                  >
                    Details <span aria-hidden="true">&rarr;</span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        )}
        <div>
          {Object.keys(years)
            .sort((a, b) => b - a)
            .map((y) => (
              <div key={y} className="">
                <PageH2>{y}</PageH2>
                <div className="">
                  <ReleasesDisplay releases={years[y]} artists noDate />
                </div>
              </div>
            ))}
        </div>
      </div>
    </Layout>
  );
};

Releases.propTypes = {
  data: PropTypes.shape({
    upcomingReleases: PropTypes.arrayOf(releaseType).isRequired,
    lastReleases: PropTypes.arrayOf(releaseType).isRequired,
  }).isRequired,
};

export default Releases;
