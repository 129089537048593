import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import { releaseType } from '../../types';
import slugify from '../../util/slugify';

const ReleaseDisplaySm = ({ release, topComponent, bottomComponent }) => (
  <Link
    to={`/releases/${slugify(release.Title)}`}
    className="rounded-xl group hover:bg-lighter-bg focus:outline-none focus:border-gray-700 p-2 border border-lighter-bg"
  >
    <div className="h-36 w-36 sm:w-56 sm:h-56">
      <GatsbyImage
        image={release.Cover.localFile.childImageSharp.gatsbyImageData}
        className="object-cover pointer-events-none select-none rounded w-full h-full"
        alt={`${release.Title} cover`}
      />
    </div>

    <div className="mt-3">
      {topComponent}
      {bottomComponent}
    </div>
  </Link>
);

ReleaseDisplaySm.propTypes = {
  release: releaseType.isRequired,
  topComponent: PropTypes.elementType.isRequired,
  bottomComponent: PropTypes.elementType.isRequired,
};

export default ReleaseDisplaySm;
